.descricao-resultado {
    margin-top: 30px;
    font-size: 17px;
}

.pagina {
    min-height: 700px;
}

.resultado {
    margin-top: 20px;
    margin-bottom: 20px;



    .titulo {
        font-weight: 500;
        font-size: 17px;
    }
    
    .subtitulo {
        font-size: 13px;
        color: green;
    }
    
    .corpo {
    
    }
}
