.texto {
    :global {
        img {
            display: block;
            margin: 0 auto;
            min-width: 50px;
            max-width: 100%;
            height: auto;
            padding: 10px;
            filter: drop-shadow(3px 3px 3px #eeeeee);
        }

        video {
            width: 100%;
            border: 1px solid #d3d3d3;
            min-height: 350px;
        }

        .nota-rodape {
            display: none;
        }
        span.nota-rodape::before, .conteudo-artigo-manual span.nota-rodape::before {      
            content: '[Nota]';
            font-size: 10pt;
            visibility: visible;
        
        }
        
        span.nota-rodape, .conteudo-artigo-manual span.nota-rodape {
            counter-increment: notarodape;
            font-size: 0.0000001px;
            max-width:40px !important;
            word-wrap: break-word;
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: #1451b4;
            vertical-align: sub;
        }
    }    
}
