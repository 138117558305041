.card-componente {
    max-width: 46%;
    margin-right: 10px;
    min-width: 397px;
    max-width: 397px;
}

.descricao-breve {
    height: 70px;
    overflow-y: auto;
}

.tecnologias {
    margin-bottom: 5px;
}

.tags {
    margin-top: 5px;
}

.inativo {
    opacity: 0.4;
}