.card-busca-principal {
    margin-top: 35px;
    background-color: #f8f8f8;
}

.input-busca-principal {
    height: 62px !important;
}

.button-busca-principal {
    margin-top: -50px !important;
    margin-right: 15px !important;

    i {
        font-size: 20px;
    }
}

.destaques-mais-acessados {
    margin-top: 20px;

    .icone {
        margin-right: 10px;
    }

    .item {
        min-height: 53px;
        display: flex;
        align-items: center;

        .ranking {
            font-size: 25px;
            color: #E6E6E6;
            font-weight: bold;
            margin-right: 10px;
        }
    }

    .card {
        padding: 20px;

    }    
    
    .tile-header {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-bottom: 10px;
        text-transform: uppercase;

        


        h3 {
            font-size: 12px;
            min-width: 170px;
            text-align: center;
            margin-top: 10px;
            margin-bottom: 10px;
            font-weight: bold;
        }

        &::before, &::after {
            display: flex;
            background: #ccc;
            content: '';
            height: 1px;
            width: 100%;
        }
    }
    
}

.banner {
    margin-top: 25px;
    margin-bottom: 25px;
    filter: drop-shadow(1px 1px 1px #dcdcdc);
}