.cabecalho {
    margin-bottom: 20px;
    margin-top: 20px;
}
.titulo {
    margin-top: 8px;
    margin-bottom: 5px;
    padding-bottom: 5px;
}

.subtitulo {
    margin-top: 1px;
    padding-top: 1px;
}


.card-componente {
    max-width: 46%;
    margin-right: 10px;
}

.descricao-breve {
    overflow-y: auto;
}

.tecnologias-categorias {
    margin-bottom: 15px;

    .tecnologias {
        margin-right: 20px;
    }
}

.categorias {
    margin-right: 20px;
}

.tags {
    margin-top: 15px;
    margin-bottom: 10px;
}

.info-lateral {
    word-break: break-all;
    margin-bottom: 10px;
    margin-top: 10px;
}

.conteudo {
    padding: 20px;

    img {
        max-width: 100%;
        height: auto;
        padding: 10px;
        filter: drop-shadow(3px 3px 3px #eeeeee);
    }

    :global {
        iframe {
            width: 100%;
        }
    }
}

.avatar-componente {
    margin-right: 10px;
}

:global {
    pre {
        overflow-y: auto;
    }
}

.icone-parametro-plugin {
    margin-right: 5px;
}

.metodo {
    border: 1px solid;
    border-radius: 5px;
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-right: 5px;
}

.metodo-get {
    border-color: #198754;
    background-color: #198754;
    color: white;
}

.metodo-post {
    border-color: #0dcaf0;
    background-color: #0dcaf0;
    color: black;
}

.metodo-put {
    border-color: #ffc107;
    background-color: #ffc107;
    color: black;
}

.metodo-delete {
    border-color: #dc3545;
    background-color: dc3545;
    color: black;
}







.postmanWrapper {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: auto;
}


:global {
    .swagger-ui pre:not(.microlight) {
        background: none !important;
    }
    
    pre.microlight {
        background: black !important;
    }
    
    .microlight code {
        color: white !important;
        background: none !important;
    }

    .swagger-ui a:hover {
        background: none !important;
    }
    
    .swagger-ui th {
        background: none !important;
    }
    
    .swagger-ui td {
        background: none !important;
    }
    
    .swagger-ui td:hover {
        background: none !important;
    }    
}